import {Component, Input} from '@angular/core';
import {NgIf} from "@angular/common";
import {SharedModule} from "../../../../@commons/ui-components/shared.module";

@Component({
  selector: 'vw-need-item',
  standalone: true,
    imports: [
        NgIf,
        SharedModule
    ],
  templateUrl: './need-item.component.html',
  styleUrl: './need-item.component.scss'
})
export class NeedItemComponent {
  @Input() label: string | number ;
  @Input() iconUrl: string;
}
