import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../@commons/ui-components/shared.module';
import { PeriodChartComponent } from '../@components/period-chart/period-chart.component';
import { Plant } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@entities/app/plant.entity';
import { PlantApi } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/location/plant/plant-api';
import { ActivatedRoute } from '@angular/router';
import {
  PlantResponse
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/responses/location/plant.response';
import { NeedsComponent } from '../@components/needs/needs.component';
import { CardBlockTitleComponent } from '../../@commons/@components/cards/card-block-title/card-block-title.component';
import {
  CultivationVariant
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@entities/app/cultivation-variant.entity';
import {
  Cultivation
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@entities/app/cultivation.entity';
import { CultivationFlatItemComponent } from '../@components/cultivation-flat-item/cultivation-flat-item.component';
import { StorageService } from '../../@commons/@services/front/storage.service';
import { HexToHsl } from '../../@commons/pipes/HexToHsl';

@Component({
  selector: 'vw-cultivation-guide', // Selector for the component
  standalone: true, // Indicates that this component is standalone
  imports: [SharedModule, CommonModule, TranslateModule, PeriodChartComponent, NeedsComponent, CardBlockTitleComponent, CultivationFlatItemComponent, HexToHsl], // Required modules for the component
  templateUrl: './cultivation-guide.component.html', // Path to the component's HTML template
  styleUrls: ['./cultivation-guide.component.scss'] // Path to the component's SCSS styles
})
export class CultivationGuideComponent {
  risks: any[] = []; // Holds the risks associated with the plant
  friends: any[] = []; // Holds the companion plants for the plant
  @Input() plant: Plant = null;
  @Input() cultivationVariant: any;
  @Input() cultivation: any;


  constructor(private plantApi: PlantApi,
    private route: ActivatedRoute,
    private storageService: StorageService) { }

  /**
   * Lifecycle hook that is called after the component is initialized.
   * Fetches plant details from the service.
   */
  ngOnInit(): void {
    const plantUid = this.route.snapshot.paramMap.get('plantUid'); // Get the plant ID from route parameters
    this.loadPlant(plantUid);
    //this.risks =  plant?.cultivationVariant?.risks//TODO
    //this.friends = plant?.cultivationVariant?.friends//TODO
    console.log(this.cultivation);
    console.log(this.cultivationVariant);
  }

  loadPlant(plantUid: string | null) {
    if (plantUid) {
      this.plantApi.find(plantUid, this.storageService.getCurrentLanguage()).then((res: PlantResponse) => {
        this.plant = res; // Assign the fetched plant details to the component property
      });
    }
  }

  getFamily() {
    const hierarchy = this.cultivation.cultivationHierarchy;
    if (hierarchy.type === 'FAMILY') {
      return hierarchy.name;
    } else if (hierarchy.parent.type === 'FAMILY') {
      return hierarchy.parent.name;
    } else {
      return hierarchy.parent.parent.name;
    }
  }
}
