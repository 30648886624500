<vw-card customClasses="bg-white rounded-xl flex gap-4 p-4 mb-3">
  <vw-text-input *ngIf="task.taskState !== 'DONE'"
    [type]="'radio'"
    [checked]="activeTaskUId === task.uid"
    (click)="selectTask(task.uid)"
    class="cursor-pointer"
  ></vw-text-input>
  <vw-icon-svg *ngIf="task.taskState === 'DONE'"
               [iconUrl]="'/assets/svg/task.svg'"
               additionalClasses="cursor-pointer"
               width="18"
  ></vw-icon-svg>

  <div>
    <p class="text-lg font-semibold leading-none pb-[2px] text-[#0C0D0D]">
      {{ task.name | Label }}
    </p>
    <!-- Task description -->
    <p class="text-[#7A8581] text-sm">
      {{ task.shortDescription }}
    </p>
    <div class="flex items-center gap-1 mt-2">
      <!--<div
        *ngFor="let meta of task.metadata"
        [class]="
            'text-xs font-semibold flex items-center gap-2 py-1 px-2 w-auto rounded-2xl' +
            ' ' + meta.color + ' border ' + meta.borderColor
          "
      >
        <vw-icon-svg *ngIf="meta.iconUrl"
                     [iconUrl]="meta.iconUrl"
                     additionalClasses="cursor-pointer"
                     width="14"
        ></vw-icon-svg>
        {{ meta.label }}
      </div>-->
    </div>


  </div>
</vw-card>
