<vw-card
  [customClasses]="
                status === 'SELECTED'
                  ? 'shadow-lg p-3 rounded-xl border-2 border-[#9AD6AD] bg-[#F8FCF9] max-h-full'
                  : 'shadow-lg p-3 rounded-xl border border-[#F0EFEF] bg-white h-full '
              "
>
  <div class="pb-4">
    <div class="flex items-start justify-end pb-2 w-full">
      <p class="p-1 cursor-pointer px-2 pb-2 rounded-lg text-xs font-semibold  leading-normal whitespace-nowrap"
         [ngClass]="{
       'border border-[#DAF2E0] bg-transparent text-primary': status === null,
       'bg-[#DAF2E0] text-[#185545]': status != null
     }">
        {{ ('garden.growing-steps.status.' + (status || 'default')) | translate }}
      </p>
    </div>

    <div>
      <!--<p class="text-xs text-secondary-secondaryB pb-1">
        {{'garden.growing-steps.item.growing-step' | translate }}
      </p>-->
      <h3
        class="text-xl text-primary-dark font-semibold"
        (click)="onCloseClicked('close')"
      >
        {{step.name}}
      </h3>
    </div>
    <!--<div class="pb-3" *ngIf="">
      <p class="text-xs text-grey-500 pb-1">
        {{'garden.growing-steps.item.period' | translate }}
      </p>
      <div class="flex items-center gap-2">
        <div
          *ngFor="let item of step.period"
          class="text-xs font-semibold text-[#565D5B] flex items-center gap-2 border border-[#C5C9C8] py-1 px-2 w-auto rounded-2xl"
        >
          {{ item }}
        </div
      </div>
    </div>-->
    <div class="pb-3" *ngIf="step.durationDays">
      <p class="text-xs text-grey-500 pb-1">
        {{'garden.growing-steps.item.duration' | translate }}
      </p>
      <p class="text-sm text-[#3C4340]">
        {{'garden.growing-steps.item.durationDays' | translate: {days: step.durationDays} }}
      </p>
    </div>
    <div class="pb-3" *ngIf="step.description">
      <p class="text-xs text-grey-500 pb-3 ">
        {{'garden.growing-steps.item.aspect' | translate }}
      </p>
      <p class="text-sm text-[#3C4340]"
         [ngClass]="{
          'h-24 overflow-scroll': !showDetails,
         }"
         [innerHTML]="step.description">
      </p>
    </div>
  </div>
  <div class="bg-[#FAFAFA] p-2 rounded-xl" *ngIf="showDetails">
    <p class="text-sm text-grey-500 pb-3">
      {{'garden.growing-steps.item.needs' | translate }}
    </p>
    <div class="w-full">
      <vw-needs [needs]="step.needs"></vw-needs>
    </div>
  </div>
</vw-card>
