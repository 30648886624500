import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'base-toast',
  template: ``,
})

export abstract class BaseToastComponent  {
  @Input() isOpen: boolean;
  @Output() close: EventEmitter<any> = new EventEmitter();

  onClose(data?: any) {
    this.isOpen = false;
    this.close.emit(data);
  }
}
