import {Component, Input} from '@angular/core';
import {NgForOf, NgIf} from "@angular/common";
import {SharedModule} from "../../../@commons/ui-components/shared.module";
import {
  CultivationNeeds
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@entities/cultivation-needs';
import {NeedItemComponent} from './need-item/need-item.component';
import {TranslateModule} from '@ngx-translate/core';
import {MapSoilTypes} from '../../../@commons/pipes/MapSoilTypes';
import {MapSunshineLevels} from '../../../@commons/pipes/MapSunshineLevels';

@Component({
  selector: 'vw-needs',
  standalone: true,
  imports: [
    NgForOf,
    SharedModule,
    NgIf,
    NeedItemComponent,
    TranslateModule,
    MapSoilTypes,
    MapSunshineLevels
  ],
  templateUrl: './needs.component.html',
  styleUrl: './needs.component.scss'
})
export class NeedsComponent {
  @Input() needs: CultivationNeeds;
  sizeIconUrl = 'assets/icons/plant-needs/size.svg';
  sunIconUrl = 'assets/icons/plant-needs/sun.svg';
  wateringIconUrl = 'assets/icons/plant-needs/watering.svg';
  soilIconUrl = 'assets/icons/plant-needs/soil.svg';
  spacingIconUrl = 'assets/icons/plant-needs/spacing.svg';
}
