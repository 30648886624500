import {Component, Input} from '@angular/core';
import {SharedModule} from "../../../@commons/ui-components/shared.module";
import {NgForOf, NgIf} from '@angular/common';
import {
  TaskListResponse
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/responses/location/task-list.response';
import {
  TaskResponse
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/responses/location/task.response';
import {LabelPipe} from '../../pipes/LabelPipe';

@Component({
  selector: 'vw-task',
  standalone: true,
  imports: [
    SharedModule,
    NgForOf,
    NgIf,
    LabelPipe
  ],
  templateUrl: './task.component.html',
  styleUrl: './task.component.scss'
})
export class TaskComponent {
  @Input() task: TaskListResponse;
  activeTaskUId: string;

  selectTask(uid: string) {
    this.activeTaskUId = uid;
  }
}
