import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  standalone: true,
  name: 'mapSoilTypes'
})
export class MapSoilTypes implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(soilTypes: string): string {
    console.log('soilTypes', soilTypes);
    //input string is an array in json
    const soilTypesArray = JSON.parse(soilTypes);
    if (!soilTypesArray || soilTypesArray.length === 0) return '';
    return soilTypesArray.map(type => this.translate.instant(`common.soil-types.${type}`)).join(", ");
  }
}
