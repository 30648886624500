<div class="flex items-center w-auto gap-2" (click)="onBackClicked($event)">
  <vw-icon-svg
    iconUrl="/assets/svg/back.svg"
    additionalClasses="cursor-pointer z-[9] relative"
    width="8"
    height="8"
  ></vw-icon-svg>
  <span class="text-primary font-semibold text-base z-[9]">
              {{ "common.back" | translate }}</span
  >
</div>
