import {Component, EventEmitter, Output} from '@angular/core';
import {SharedModule} from "../../../ui-components/shared.module";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'vw-header-back-btn',
  standalone: true,
    imports: [
        SharedModule,
        TranslateModule
    ],
  templateUrl: './header-back-btn.component.html',
  styleUrl: './header-back-btn.component.scss'
})
export class HeaderBackBtnComponent {
  @Output() back: EventEmitter<void> = new EventEmitter<void>();

  onBackClicked($event) {
    this.back.emit();
  }
}
