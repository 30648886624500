import {Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild} from '@angular/core';
import {UploadIconComponent} from '../../../@icons/upload-icon.component';
import {NgClass, NgIf} from '@angular/common';
import {FileApi} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/file/file-api';
import {
  UploadFileRequest
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/requests/dms/upload-file-request';
import {FileType} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@enums/file-type.enum';
import {
  UploadFileResponse
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/responses/dms/upload-file-response';
import {CloseIconComponent} from '../../../@icons/close-icon.component';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ImgComponent} from '../img/img.component';

@Component({
  selector: 'vw-img-uploader',
  standalone: true,
  templateUrl: './img-uploader.component.html',
  imports: [
    UploadIconComponent,
    NgClass,
    NgIf,
    ImgComponent,
    CloseIconComponent
  ],
  providers: [
    {
      provide: FileApi,
      useClass: FileApi
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImgUploaderComponent),
      multi: true
    }
  ],
  styleUrl: './img-uploader.component.scss'
})
export class ImgUploaderComponent  implements ControlValueAccessor {

  isLoading = false;
  @Input() value: UploadFileResponse;
  @Output() fileSelected: EventEmitter<UploadFileResponse> = new EventEmitter<any>();

  @Input() disabled: boolean = false; // Input property to control whether the input is disabled
  @Input() checked: boolean = false; // Input property for checkbox state
  @Input() isError: boolean = false; // Input property to indicate if there is an error state
  @Output() valueChange = new EventEmitter<UploadFileResponse>(); // Event emitter for value changes

  @ViewChild('fileInput') fileInput: ElementRef;
  onChange = (value: UploadFileResponse) => {};
  onTouched = () => {};

  constructor(private fileApi: FileApi) {
  }

  // Déclencher l'input file au clic
  triggerFileUpload() {
    this.fileInput.nativeElement.click();
  }

  // Gestion de la sélection de fichier
  onFileSelected(event: Event) {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (file) {
      const uploadFileRequest: UploadFileRequest = new UploadFileRequest();
      uploadFileRequest.file = file;
      uploadFileRequest.fileName = file.name;
      uploadFileRequest.fileType = file.type as FileType;
      this.fileApi.uploadFile(uploadFileRequest).then((fileResponse: UploadFileResponse) => {
        this.value = fileResponse as UploadFileResponse;
        this.onChange(this.value);
        this.onTouched();
        this.valueChange.emit(this.value);
      });
    }
  }

  deleteFile() {
    this.value = null;
  }

  writeValue(value: UploadFileResponse): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
