import { Injectable } from '@angular/core';
import * as Highcharts from 'highcharts/highcharts-gantt';
import { CultivationPeriod } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@entities/app/cultivation-period.entity';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class ChartService {
  translations: any = {};

  constructor(private translationService: TranslateService) {
    this.translationService.get('garden.guide.periods.types').subscribe((translations: any) => {
      this.translations = translations;
    });
  }

  getChartOptions(periods: CultivationPeriod[]): Highcharts.Options {

    if (!periods || periods.length === 0) {
      return {}; // Évite les erreurs si aucune donnée
    }


    const translations = this.translations;

    // Extraire les catégories uniques à partir des enums (SEEDLING, PLANTING, HARVEST, etc.)
    const originalCategories = Array.from(new Set(periods.map(p => p.type.toUpperCase())));

    // Générer un mapping des catégories aux indices de l'axe Y
    const categoryMap: { [key: string]: number } = {};
    originalCategories.forEach((category, index) => {
      categoryMap[category] = index;
    });

    console.log(periods);


    // Transformer les périodes en objets compatibles avec Highcharts
    const seriesData = periods.map(period => {
      const startMonth = moment(period.startDate).month(); // 0 = Janvier, 11 = Décembre
      const startDay = moment(period.startDate).date();
      const endMonth = moment(period.endDate).endOf('month').month();
      const endDay = moment(period.endDate).endOf('month').date();

      // Si la période passe d'une année à l'autre, on met l'endDate à 2025
      const endYear = endMonth < startMonth ? 2025 : 2024;

      return {
        name: this.translations[period.type.toUpperCase()] || period.type,
        start: Date.UTC(2024, startMonth, startDay), // Date fixe pour l'affichage
        end: Date.UTC(endYear, endMonth, endDay), // Ajustement si chevauchement
        y: categoryMap[period.type.toUpperCase()],
        color: ChartService.getCategoryColor(period.type.toUpperCase()),
        type: period.type,
      };
    });


    return {
      credits: { enabled: false },
      chart: {
        plotBorderWidth: 0,
        borderWidth: 1,
        borderRadius: 10,
        borderColor: '#EBEBEA',
        spacing: [0, 0, 0, 10],
        height: 200,
      },
      xAxis: [{
        alignTicks: false,
        min: Date.UTC(2024, 0, 1),
        max: Date.UTC(2024, 11, 31),
        tickInterval: 30 * 24 * 3600 * 1000, // Mois
        labels: {
          format: '{value:%b}',
          style: { fontSize: '20px', fontWeight: '500', color: '#A4A19D' },
          formatter: function () {
            const monthNames = ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'];
            return monthNames[new Date(this.value).getUTCMonth()];
          },
        },
        gridLineWidth: 1,
        gridLineColor: '#EBEBEA',
      }],
      yAxis: {
        categories: originalCategories, // Garder les enums dans les catégories
        title: { text: null },
        labels: {
          align: 'left',
          y: 0,
          style: { fontSize: '20px' },
          formatter: function () {
            // Récupérer l'index de la catégorie pour trouver l'enum original
            const index = this.pos;
            const categoryEnum = this.axis.categories[index]; // Récupération de l'enum (ex: "SEEDLING")
            const color = ChartService.getCategoryColor(categoryEnum);
            // Afficher la traduction en conservant la couleur de l'enum
            return `
                    <span class="title-chart" style="color: ${color}">${translations[categoryEnum]}</span>`;
          },
        },
        gridLineWidth: 2,
      },
      series: [{
        type: 'gantt',
        name: '',
        data: seriesData,
        groupPadding: 0.38,
        borderRadius: 15,
      }],
      plotOptions: {
        series: {
          dataLabels: {
            enabled: false,
            format: '{point.name}',
          },
        },
      },
    };
  }

  /**
   * Récupère la couleur associée à une catégorie d'enum.
   */
  private static getCategoryColor(category: string): string {
    const colors: { [key: string]: string } = {
      SEEDLING: '#f3c518',
      PLANTING: '#136a4a',
      HARVEST: '#a4de56',
    };
    return colors[category] || '#999999'; // Couleur par défaut
  }

}
