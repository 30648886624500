import {Component, Input} from '@angular/core';
import {NgClass, NgIf} from '@angular/common';
import {SharedModule} from '../../ui-components/shared.module';

@Component({
  selector: 'vw-img',
  standalone: true,
  imports: [
    NgIf,
    SharedModule,
    NgClass
  ],
  templateUrl: './img.component.html',
  styleUrl: './img.component.scss'
})
export class ImgComponent {
  @Input() src: string;
  @Input() rounded?: boolean;
  @Input() backgroundColor: string = null;
}
