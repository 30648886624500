var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import axios from 'axios';
import { BaseApi } from '../@common/base-api';
export class FileApi extends BaseApi {
  constructor() {
    super('external-files');
  }
  createSignedUrl(request) {
    return __awaiter(this, void 0, void 0, function* () {
      return this.post('create-signed-url', request);
    });
  }
  updateExternalFile(request) {
    return __awaiter(this, void 0, void 0, function* () {
      return this.patch('', request);
    });
  }
  uploadFile(request) {
    return __awaiter(this, void 0, void 0, function* () {
      const signedUrl = yield this.createSignedUrl({
        fileName: request.fileName,
        fileType: request.fileType
      });
      yield axios.put(signedUrl.signedUrl, request.file, {
        headers: {
          'Content-Type': request.fileType || 'application/octet-stream'
        }
      });
      return {
        externalFileUid: signedUrl.externalFileUid,
        fileKey: signedUrl.fileKey,
        cdnUrl: signedUrl.cdnUrl
      };
    });
  }
}