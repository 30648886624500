import {Component, Input} from '@angular/core';
import {NgForOf} from "@angular/common";
import {SharedModule} from "../../../@commons/ui-components/shared.module";

@Component({
  selector: 'vw-article',
  standalone: true,
    imports: [
        NgForOf,
        SharedModule
    ],
  templateUrl: './article.component.html',
  styleUrl: './article.component.scss'
})
export class ArticleComponent {
  @Input() article: any;
}
