
<div *ngIf="src && !rounded"
  class="custom-background-img overflow-hidden" [style.background-color]="backgroundColor || ''" [style.background-image]="'url('+src+')'"></div>

<div *ngIf="rounded "
     class="border border-primary listing-inn shadow-lg rounded-xl w-full h-full
     bg-white cursor-pointer overflow-hidden relative">
  <div class="custom-background-img absolute inset-0"
       [style.background-color]="backgroundColor || ''"
       [style.background-image]="'url('+src+')'"></div>
</div>
