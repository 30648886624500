import { Component } from '@angular/core';

@Component({
  selector: 'vw-carrot-icon',
  standalone: true,
  imports: [],
  template: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.63953 14L6.58953 11.96M15.3395 15L12.8795 12.54M14.9995 9C14.9995 9 16.8595 7 18.4995 7C20.6695 7 21.9995 9 21.9995 9C21.9995 9 20.6695 11 18.4995 11C16.3295 11 14.9995 9 14.9995 9ZM14.9995 9C14.9995 9 12.9995 7.67 12.9995 5.5C12.9995 3.33 14.9995 2 14.9995 2C14.9995 2 16.9995 3.33 16.9995 5.5C16.9995 7.16 14.9995 9 14.9995 9ZM2.26953 21.7C2.26953 21.7 12.1395 18.2 14.9995 15.34C15.4178 14.9224 15.7497 14.4265 15.9763 13.8807C16.2029 13.3348 16.3198 12.7496 16.3203 12.1586C16.3207 11.5675 16.2048 10.9822 15.979 10.4359C15.7533 9.88971 15.4221 9.39329 15.0045 8.97503C14.5869 8.55677 14.091 8.22486 13.5452 7.99825C12.9993 7.77164 12.4141 7.65476 11.8231 7.6543C11.232 7.65383 10.6467 7.76979 10.1004 7.99554C9.55421 8.2213 9.05779 8.55243 8.63953 8.97003C5.76953 11.84 2.26953 21.7 2.26953 21.7Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>`,
})
export class CarrotIconComponent {

}
