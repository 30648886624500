<div class="overflow-auto h-full">
  <div class="w-full flex items-center justify-between pt-8 pb-2 px-4"
    [style.background-color]="cultivationVariant?.backgroundColor | HexToHsl">
    <div class="flex flex-col gap-2">
      <h1 class="font-semibold text-3xl">{{cultivationVariant?.name}}</h1>
      <span
        class="font-semibold text-xs py-1 px-2 rounded-full text-[#545454] border border-[#545454] w-fit">{{getFamily()}}</span>
    </div>
    <div class="w-48 h-32"><vw-icon-svg [iconUrl]="cultivation?.files[0].cdnUrl || '/assets/svg/tomato-3.svg'"
        additionalClasses="mx-auto h-full w-8/12 object-contain" width="100%" height="100%"></vw-icon-svg></div>
  </div>
  <div class="">
    <div class="p-4 overflow-auto flex flex-col gap-4">
      <div class=" bg-white rounded-lg shadow-lg px-3 py-2">
        <vw-card-block-title label="garden.guide.description" />
        <p class="text-sm" [innerHTML]="cultivationVariant?.description || cultivation?.description"></p>
      </div>
      <div class="bg-white rounded-lg shadow-lg px-3 py-2">
        <vw-card-block-title label="garden.guide.needs" />
        <div class="w-full">
          <vw-needs [needs]="cultivationVariant?.needs || cultivation?.needs"></vw-needs>
        </div>
      </div>
      <div class="bg-white rounded-lg shadow-lg px-3 py-2">
        <vw-card-block-title label="garden.guide.periods.title" />
        <div class="gantt-chart-container">
          <vw-period-chart [variant]="cultivationVariant"></vw-period-chart>
        </div>
      </div>
      <div class="bg-white rounded-lg shadow-lg px-3 py-2">
        <vw-card-block-title label="garden.guide.associations" />
        <div class="flex flex-wrap gap-2 mb-2">
          <ng-container *ngFor="let friend of friends">
            <div [ngClass]="{
                'text-[#3D9D1D] border-[#A1E97B]': friend.type === 'friend',
                'text-[#C5132E] border-[#F78373]': friend.type === 'foe'
              }" class="text-xs font-semibold flex items-center gap-2 border py-1 px-2 rounded-lg bg-white">
              <vw-icon-svg [iconUrl]="friend.iconUrl" additionalClasses="cursor-pointer" width="14"></vw-icon-svg>
              {{ friend.name }}
            </div>
          </ng-container>
        </div>
      </div>
      <div class="bg-white rounded-lg shadow-lg px-3 py-2">
        <vw-card-block-title label="garden.guide.risks" />
        <div class="flex items-center justify-center gap-4">
          <ng-container *ngFor="let risk of risks">
            <div
              class="text-base h-12 font-semibold text-primary-dark flex items-center justify-between gap-2 border border-[#EBEBEA] py-3 px-3 rounded-lg bg-white w-full">
              {{ risk.name }}
              <vw-icon-svg [iconUrl]="risk.iconUrl" additionalClasses="cursor-pointer rotate-180"
                width="20"></vw-icon-svg>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
