import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {NgClass, NgForOf, NgIf} from '@angular/common';
import {SharedModule} from '../../../@commons/ui-components/shared.module';
import {
  GrowingStepListResponse
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/responses/cultivation/growing-step-list.response';
import {
  GrowingStep
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@entities/app/growing-step.entity';
import {NeedsComponent} from '../needs/needs.component';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'vw-growing-step-slide-viewer',
  standalone: true,
  imports: [
    NgForOf,
    SharedModule,
    NeedsComponent,
    TranslateModule,
    NgClass,
    NgIf
  ],
  templateUrl: './growing-step-slide-viewer.component.html',
  styleUrl: './growing-step-slide-viewer.component.scss'
})
export class GrowingStepSlideViewerComponent {
  @Input() step: GrowingStep;
  @Input() totalSteps: number;
  @Input() stepIndex: number;
  @Input() showDetails: boolean = true;
  @Input() status: 'SELECTED' | 'CURRENT' | null = null;
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectStep: EventEmitter<GrowingStep> = new EventEmitter<GrowingStep>();

  onCloseClicked(closeE: string) {
    this.close.emit(closeE);
  }

  onSelectStepClicked(step: GrowingStep) {
    this.selectStep.emit(step);
  }
}
