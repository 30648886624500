import {Component, Input} from '@angular/core';
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'vw-card-block-title',
  standalone: true,
    imports: [
        TranslateModule
    ],
  templateUrl: './card-block-title.component.html',
  styleUrl: './card-block-title.component.scss'
})
export class CardBlockTitleComponent {
  @Input() label: string;
}
