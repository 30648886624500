import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {HighchartsChartModule} from 'highcharts-angular'; // Importing the Highcharts module for Angular
import * as Highcharts from 'highcharts/highcharts-gantt'; // Importing Highcharts and the Gantt chart module
import {ChartService} from '../../@services/chart.service'; // Importing the ChartService for chart options
import {CommonModule} from '@angular/common';
import {
  CultivationPeriod
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@entities/app/cultivation-period.entity';
import {
  CultivationVariant
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@entities/app/cultivation-variant.entity';
import {
  CultivationPeriodApi
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/cultivation/cultivation-period/cultivation-period-api';
import {
  CultivationPeriodListRequest
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/requests/cultivation/cultivation-period-list.request';
import {StorageService} from '../../../@commons/@services/front/storage.service'; // Importing CommonModule for common Angular directives

@Component({
  selector: 'vw-period-chart', // Selector for the period chart component
  standalone: true, // Indicates that this component is standalone
  imports: [CommonModule, HighchartsChartModule], // Required modules for the component
  templateUrl: './period-chart.component.html', // Path to the component's HTML template
  styleUrls: ['./period-chart.component.scss'], // Path to the component's SCSS styles
  providers: [
    {
      provide: CultivationPeriodApi,
      useClass: CultivationPeriodApi
    }
  ]
})
export class PeriodChartComponent implements OnInit, OnChanges {
  @Input() variant: CultivationVariant;
  Highcharts: typeof Highcharts = Highcharts; // Highcharts reference for the component
  chartOptions: Highcharts.Options; // Variable to hold chart options

  /**
   * Constructor to initialize the PeriodChartComponent.
   * @param {ChartService} chartService - Service to fetch chart options
   */
  constructor(private chartService: ChartService,
              private storageService: StorageService,
              private cultivationPeriodApi: CultivationPeriodApi) {
  }

  /**
   * Lifecycle hook that is called after the component has been initialized.
   * Fetches the chart options from the ChartService and assigns them to chartOptions.
   */
  async ngOnInit() {
    await this.loadPeriods();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['variant']) {
      await this.loadPeriods();
    }
  }

  /**
   * Method to load the cultivation periods for the variant.
   */
  async loadPeriods() {
    if (this.variant) {
      const listCultivationPeriods: CultivationPeriodListRequest = new CultivationPeriodListRequest();
      listCultivationPeriods.cultivationVariantUid = this.variant.uid;
      const periods = await this.cultivationPeriodApi.list(listCultivationPeriods, 0, 20, this.storageService.getCurrentLanguage());
      this.chartOptions = this.chartService.getChartOptions(periods.results);
    }
  }

}
