<div [ngClass]="{'border border-primary listing-inn': value}"
     class="shadow-lg rounded-xl bg-primary border-white border-2 cursor-pointer items-center w-28 h-28 flex justify-center"
     (click)="triggerFileUpload()">

  <div class="h-20 w-20 flex items-center justify-center" *ngIf="!value">
    <vw-upload-icon class="text-white h-10 w-10"></vw-upload-icon>
  </div>

  <div class="w-full h-full border border-primary overflow-hidden listing-inn shadow-lg rounded-xl bg-white relative" *ngIf="value">
    <!--overlay for selection -->
      <vw-img class="h-full w-full" [src]="value.cdnUrl"></vw-img>
      <vw-close-icon class="text-primary rounded-2xl bg-white absolute top-1 right-1"
                     (click)="deleteFile()">
      </vw-close-icon>
  </div>
  <input #fileInput accept="image/*" type="file" (change)="onFileSelected($event)" class="hidden" />
</div>
