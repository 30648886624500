import { Component, EventEmitter, Input, Output, OnDestroy, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OnboardingService } from '../../../onboarding/@services/onboarding.service';
import { SelectionOptions } from '../../../onboarding/@models/selection-options.interface';
import { InteriorOptions } from '../../../onboarding/@models/interior-options.interface';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared.module';
import { CloseBtnComponent } from "../../@components/close-btn/close-btn.component";
import { TranslateModule } from '@ngx-translate/core';
import { ImgUploaderComponent } from '../../@components/img-uploader/img-uploader.component';
import { CheckIconComponent } from '../../../@icons/check-icon.component';
@Component({
  selector: 'vw-custom-form', // Component selector for app-account-form
  standalone: true, // Declares the component as standalone
  imports: [SharedModule, CommonModule, FormsModule, CheckIconComponent, CloseBtnComponent, TranslateModule, ImgUploaderComponent], // Modules and components required for this component
  templateUrl: './custom-form.component.html', // HTML template path
  styleUrl: './custom-form.component.scss', // Stylesheet path
})
export class CustomFormComponent implements OnInit, OnDestroy, OnChanges {

  @Input() isOpen: boolean = false;
  @Input() formConfig: any; // General config object
  @Output() closeBottomSheet = new EventEmitter<void>();
  @Output() submitForm = new EventEmitter<any>();
  @Input() formData: any = {};
  @Input() loading: boolean = false;

  selectionOptions: any = {}

  constructor(private onboardingService: OnboardingService) { }

  ngOnInit(): void {
    this.loadDropdownValues();
  }

  ngOnChanges(changes: SimpleChanges) {
    /*if (changes['formConfig'] && changes['formConfig'].currentValue) {
      this.loadDropdownValues();
    }*/
  }

  loadDropdownValues(): void {
    if (this.formConfig && this.formConfig.fields) {
      this.formConfig.fields.forEach((field: any) => {
        if (field.type === 'dropdown') {
          this.selectionOptions[field.name] = field.possibleValues;
        }
      });
    }
    console.log(this.selectionOptions);
  }

  closeSheet(): void {
    this.closeBottomSheet.emit();
  }

  onSubmitForm(): void {
    console.log('submitAccountForm');
    if (this.isFormValid()) {
      this.submitForm.emit(this.formData);
    } else {
    }
  }
  isFormValid(): boolean {
    let isValid = true;
    this.formConfig.fields.forEach((field: any) => {
      if (field.required && !this.formData[field.name]) {
        isValid = false;
        // Optionally mark the field as having an error for visual indication
        field.hasError = true;
      } else {
        field.hasError = false;
      }
    });
    return isValid;
  }

  ngOnDestroy(): void {
  }

}
