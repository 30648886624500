import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  standalone: true,
  name: 'mapSunshineLevels'
})
export class MapSunshineLevels implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(sunshine: string): string {
    //input string is an array in json
    const sunshineLevels = JSON.parse(sunshine);
    if (!sunshineLevels || sunshineLevels.length === 0) return '';
    return sunshineLevels.map(type => this.translate.instant(`common.sunshine-levels.${type}`)).join(", ");
  }
}
