<div class="h-full min-h-screen flex flex-col" *ngIf="isOpen">
  <!-- Section titre et description -->
  <div class="w-full p-3 pb-4 pt-4">
    <h2 class="text-primary-dark text-[24px] font-semibold">{{ formConfig.title | translate }}</h2>
    <p class="text-base">{{ formConfig.description | translate }}</p>
  </div>

  <!-- Contenu scrollable -->
  <div class="border-t border-[#DFE2E1] overflow-auto pb-28 flex-1 px-4 py-4">
    <div class="flex flex-col gap-6">
      <div *ngFor="let field of formConfig.fields">
        <div class="w-full relative">
          <span class="text-xs font-normal text-[#9BA6A1] pb-2 flex">{{ field.label | translate }}</span>
          <ng-container>
            <vw-img-uploader *ngIf="'img' === field.type" [(ngModel)]="formData[field.name]"
              class="w-full"></vw-img-uploader>
            <vw-text-input *ngIf="'text' === field.type" [type]="'text'" [placeholder]="field.placeholder"
              [(ngModel)]="formData[field.name]" class="type-date w-full"></vw-text-input>
            <vw-text-input *ngIf="'date' === field.type" [type]="'date'" [placeholder]="field.placeholder"
              [(ngModel)]="formData[field.name]" class="type-date w-full"></vw-text-input>
            <vw-dropdown *ngIf="'dropdown' === field.type && selectionOptions && selectionOptions[field.name]"
              [options]="selectionOptions[field.name]" backgroundStyle="white" textStyle="black"
              [(ngModel)]="formData[field.name]" [placeholder]="field.placeholder"></vw-dropdown>
          </ng-container>

          <!-- Message d'erreur -->
          <div *ngIf="field.hasError" class="text-red-500 text-sm absolute pt-1">
            {{ field.label | translate }} is required.
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="sticky bottom-0  p-6 w-full">
    <vw-button class="mx-auto" (click)="onSubmitForm()" label="{{ formConfig.submitButtonLabel | translate }}"
       [iconPosition]="'left'" [color]="'bg-primary hover:bg-black'"
      [iconTemplate]="iconTemplate" [textColor]="'text-white hover:text-white'" customClass="text-center mx-auto "
      [loading]="loading">
      <ng-template #iconTemplate>
        <vw-check-icon></vw-check-icon>
      </ng-template>
    </vw-button>
  </div>
</div>