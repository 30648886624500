<vw-need-item *ngIf="needs?.size?.height" [iconUrl]="sizeIconUrl"
              [label]="'garden.components.needs.size' | translate:{width: needs.size.width, height: needs.size.height, deep: needs.size.deep}"></vw-need-item>
<vw-need-item *ngIf="needs?.sun?.exposition" [iconUrl]="sunIconUrl || '/assets/svg/sun2.svg'"
              [label]="'garden.components.needs.sun' | translate:{exposition: (needs.sun.exposition | mapSunshineLevels), duration: needs.sun.duration}"></vw-need-item>
<vw-need-item *ngIf="needs?.watering?.frequency" [iconUrl]="wateringIconUrl || '/assets/svg/rain.svg'"
              [label]="'garden.components.needs.watering' | translate:{frequency: needs.watering.frequency, quantity: needs.watering.quantity}"></vw-need-item>
<vw-need-item *ngIf="needs?.soil?.type" [iconUrl]="soilIconUrl"
              [label]="'garden.components.needs.soil' | translate:{value: (needs.soil.type | mapSoilTypes)}"></vw-need-item>
<vw-need-item *ngIf="needs?.spacing?.right" [iconUrl]="spacingIconUrl"
              [label]="'garden.components.needs.spacing' | translate:{left: needs.spacing.left, right: needs.spacing.right}"></vw-need-item>
