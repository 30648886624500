import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BaseToastComponent } from '../../../@commons/ui-components/toast-popup/base-toast-component';
import { SharedModule } from '../../../@commons/ui-components/shared.module';
import { CultivationGuideComponent } from '../../cultivation-guide/cultivation-guide.component';
import { CloseBtnComponent } from '../../../@commons/@components/close-btn/close-btn.component';
import { Plant } from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@entities/app/plant.entity';
import { CommonModule } from '@angular/common';
import {
  CultivationVariant
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@entities/app/cultivation-variant.entity';
import {
  Cultivation
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@entities/app/cultivation.entity';

@Component({
  selector: 'vw-cultivation-guide-toast',
  standalone: true,
  imports: [
    SharedModule,
    CultivationGuideComponent,
    CloseBtnComponent,
    CommonModule
  ],
  templateUrl: './cultivation-guide-toast.component.html',
  styleUrl: './cultivation-guide-toast.component.scss'
})
export class CultivationGuideToastComponent extends BaseToastComponent {
  @Input() plant: Plant = null;
  @Input() cultivationVariant: CultivationVariant;
  @Input() cultivation: Cultivation;

  constructor() {
    super();
  }


}
